import React from "react"
import Layout from "../components/layout"
import Provider from "../components/provider"
import "./adatkezeles.css"

const PrivacyPage = () => (
  <Layout>
    <div className="privacy_container sized-container">
      <h1>Adatkezelési Szabályzat</h1>
      <h2>1. Adatkezelő adatai</h2>
      <Provider />
      <h2>2. Adatkezelési nyilvántartási azonosítója</h2>
      <p><b>NAIH-75466/2014</b></p>
      <h2>3. Adatkezelési jognyilatkozat</h2>
      <p>
        A Feladatkiadás szolgáltatás üzemeltetője kijelenti, hogy a személyes adatok védelméről 
        és a közérdekű adatok nyilvánosságáról szóló 1992. évi LXIII. Törvény, és az egyének 
        védelméről a személyes adatok gépi feldolgozása során szóló 1998. évi VI. törvény 
        rendelkezéseit maradéktalanul megtartva kezeli, valamint figyelembe veszi a 2001. évi CVIII. 
        Törvény (Az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről) és az 
        'Online Privacy Allience' ajánlásait.3. Díj és díjfizetés
      </p>
      <h2>4. Tagsághoz szükséges adatok</h2>
      <h3>4.1 Regisztrációkor/jelentkezéskor kötelezően megadott adatok:</h3>
      <ul>
        <li>Név/Cégnév</li>
        <li>Számlázási címe</li>
        <li>Levelezési címe</li>
        <li>E-mail cím</li>
      </ul>
      <p>
        A fenti adatokat kizárólag a szolgáltatás számlázásához használjuk fel, illetve az e-mail címet 
        a rendszer soha nem mutatja, ezzel kiszűrve a kéretlen levelek küldését. Ezen adatok nyilvánosan 
        nem kerülnek ki az Internetre, kizárólag a bejelentkezett tagok láthatják azokat.
      </p>
      <h2>5. Adatkezelés</h2>
      <h3>5.1 Adatok tárolása</h3>
      <p>
        A megadott személyes adatokat az üzemeltető a tőle elvárható legnagyobb gonddal tárolja és védi. 
        Azokat harmadik félnek nem adja ki. Az üzemeltető harmadik fél számára csak a felhasználó előzetes 
        tájékoztatása és hozzájárulása esetén ad át adatokat. Ez nem vonatkozik az esetleges, törvény 
        alapján kötelező adattovábbításokra.
      </p>
      <h3>5.2 Adatok közlése, nyilvánossá tétele</h3>
      <p>
        A megadott információkat az üzemeltető nyilvánosan nem teszi közzé, azok kizárólag a többi tag számára 
        jelszóval megerősített belépés után válnak megtekinthetővé. Ettől eltérni csak a felhasználó előzetes 
        tájékoztatása és hozzájárulása esetén lehetséges.
      </p>
      <h3>5.3 Adatok módosítása</h3>
      <p>
        A személyes és egyéb adatok módosítását kizárólag az adott felhasználó végezheti. A rendszer üzemeltetője 
        semmilyen változtatást nem végez a személyes adatokon, kivéve, ha ezt az adott felhasználó írásban kéri tőle.
      </p>
      <h3>5.4 Adatok törlése</h3>
      <p>
        A felhasználó a regisztrációkor megadott e-mail címének kivételével minden személyes adatát törölheti, módosíthatja. 
        Ezen törlés és módosítás azonnal érvényesül az adatbázisban. Az e-mail címet a visszaélések elkerülése érdekében 
        csak visszaellenőrzés után lehet módosítani.
      </p>
      <p>
        A felhasználó bármikor, indoklás nélkül kérheti a személyes adatainak törlését. Az üzemeltető ezt a felhasználó 
        visszaellenőrzése után haladéktalanul megteszi.
      </p>
      <p>
        Az üzemeltető a felhasználó tagságának megszűnését követően minden általa felvitt adatot megsemmisít.
      </p>
      <h3>5.5 Adatok archiválása</h3>
      <p>
        Az üzemeltető naponta archiválja a teljes adatbázist annak érdekében, hogy az adatok ne vesszenek el, 
        valamely hardver meghibásodása, külső behatás vagy egyéb előre nem látható ok miatt. Az üzemeltető az 
        archívumot kizárólag helyreállításra használhatja, kivéve, ha egy felhasználó a saját adataiban 
        történt hibás módosítása miatt kéri annak visszaállítását.
      </p>
      <p>
        Az archívum 10 napig visszamenőleg tárolja az adatokat, így az adattörlések is ekkor jutnak érvényre az archívumban.
      </p>
      <h2>6. Az adatkezelési szabályzat módosításának lehetősége</h2>
      <p>
        Az üzemeltető fenntartja a jogot, hogy jelen adatkezelési szabályzatot a felhasználók előzetes értesítése mellett 
        egyoldalúan módosítsa. A felhasználó elfogadja a módosított adatkezelési szabályzatot, amennyiben annak 
        hatálybalépése után belép a rendszerbe.
      </p>
      <h2>7. A felhasználók jogai személyes adataik kezelésével kapcsolatban.</h2>
      <p>
        Személyes adatai kezeléséről a felhasználók tájékoztatást kérhetnek e-mailben 
        (<a href="mailto:info@feladatkiadas.hu">info@feladatkiadas.hu</a>), melyről az üzemeltető részletes információt 
        ad a kérés beérkezését követő 8 napon belül.
      </p>
      <p>
        A felhasználó bármikor indoklás nélkül kérheti a rendszerben tárolt adatainak törlését, melyet az üzemeltető az 
        5. pontban leírtaknak megfelelően teljesít.
      </p>
      <h2>8. Jogérvényesítési lehetőségek.</h2>
      <p>
        A felhasználó jogérvényesítési lehetőségeit az Adatvédelmi törvény valamint a Ptk. alapján bíróság előtt gyakorolhatja. 
        Kérdéseivel észrevételeivel bármikor fordulhat az üzemeltetőhöz 
        az <a href="mailto:info@feladatkiadas.hu">info@feladatkiadas.hu</a> címre írt levelében.
      </p>
    </div>
  </Layout>
)

export default PrivacyPage